import Vue from 'vue';
import Router from 'vue-router';

/* Router Modules */
// import vuetifyRouter from '@/demo/router/vuetify';
// import nestedRouter from '@/demo/router/nested';
// import componentsRouter from '@/demo/router/components';
// import authRouter from './modules/auth';
// import errorsRouter from './modules/errors';
// import permissionRouter from './modules/permission';

Vue.use(Router);

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its
 *                                child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    [expected] noCache: true     if true, the page will no be cached(default is false)
    [expected] breadcrumb: false if false, the item will hidden in breadcrumb(default is true)
    [expected] affix: true       if true, the tag will affix in the tags-view
  }
 */

export const constantRoutes = [
  {
    path: '/gateway',
    alias: '/',
    name: 'gateway',
    component: () => import('@/views/gateway/index.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/options',
    alias: '/options',
    name: 'options',
    component: () => import('@/views/gateway/Options.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/payment',
    alias: '/payment',
    name: 'payment',
    component: () => import('@/views/gateway/Payment.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/pin',
    alias: '/pin',
    name: 'pin',
    component: () => import('@/views/gateway/Pin.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
    props: true
  },
  {
    path: '/GCash',
    alias: '/GCash',
    name: 'GCash',
    component: () => import('@/views/gateway/Gcash.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
    props: true
  },
  //landbank
  {
    path: '/Landbank',
    alias: '/Landbank',
    name: 'Landbank',
    component: () => import('@/views/gateway/Landbank.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
    props: true
  },
  {
    path: '/success',
    alias: '/success',
    name: 'success',
    component: () => import('@/views/gateway/Success.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/reroute',
    alias: '/reroute',
    name: 'reroute',
    component: () => import('@/views/gateway/Reroute.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/fail',
    alias: '/fail',
    name: 'fail',
    component: () => import('@/views/gateway/Error.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/401',
    alias: '/401',
    name: '401',
    component: () => import('@/views/gateway/401.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/404',
    alias: '/404',
    name: '404',
    component: () => import('@/views/gateway/404.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  },
  {
    path: '/500',
    alias: '/500',
    name: '500',
    component: () => import('@/views/gateway/500.vue'),
    hidden: true,
    meta: {
      requiresAuth: false,
      displayToolBar: false
    },
  }
  // ...authRouter,
];

export default new Router({
  mode: 'history', // gh-pages
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

export const asyncRoutes = [
  /** When your routing table is too long, you can split it into small modules */
  // permissionRouter,
  // vuetifyRouter,
  // ...componentsRouter,
  // nestedRouter,
  // errorsRouter,
  { path: '*', redirect: '/error/404', hidden: true },
];
