<template>
<v-app class="template-height">
    <component :is="layout">
        <router-view />
    </component>
    <v-footer padless>
        <v-col class="text-center" cols="12" style="margin-bottom: -70px">
            <v-img src="/img/PayConnect.png" alt="Pay-Connect" contain max-height="20" />
        </v-col>
    </v-footer>
</v-app>
</template>

<script>
import TheLayout from '@/views/layout/TheLayout.vue';

export default {
    name: 'App',
    components: {
        TheLayout,
    },
    computed: {
        layout() {
            return 'the-layout';
        },
    },
    data() {
        return {
            customerDetails: {
                mobile: null,
                amount: null,
                PO: null,
                CRN: null,
                MC: null,
                sig: null,
                description: null,
                timestamp: null,
                AN: null,
                token: null,
                sig2: null,
                url: null,
                accountName: null,
                transactionType: null,
                email: null,
            }
        }
    },
    async created() {
        console.log('app.vue--------')
        if (location.search.trim() !== '') {
          console.log('has params')
            let query = decodeURIComponent(location.search.substring(1))
            let queries = query.split('&')
                .map(q => {
                    let key = q.substring(0, q.indexOf('='));
                    let value = q.substring(q.indexOf('=') + 1);
                    return {
                        key,
                        value,
                    }
                });
            if ((location.pathname === '/' || location.pathname === '/gateway' || location.pathname === '/options' || location.pathname === '/payment') && queries.length > 0) {
                console.log('set data')
                for (var q in queries) {
                    this.customerDetails[queries[q].key] = queries[q].value
                }
                this.customerDetails.url = window.location.href + location.search
                this.$store.dispatch('setCustomerData', this.customerDetails)
                this.$router.replace({
                    path: location.pathname,
                    query: null
                })
            } else {
                console.log('remove saved data')
                this.$store.dispatch('setCustomerData', this.customerDetails)
            }
        }
    }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
}

* {
    font-family: 'Product Sans', 'Open Sans', 'OpenSans', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    margin: 0;
    padding: 0;
}
</style>
