<template>
<v-navigation-drawer color="primary" :value="navbarShow" width="289" absolute :style="{top: '64px'}" @input="stateNavbarShow">
    <div class="left-notch">
        <the-layout-drawer-list :dense="navbarDense" :routes="permissionRoutes" icon-show />
    </div>
</v-navigation-drawer>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import TheLayoutDrawerList from './TheLayoutDrawerList.vue';

export default {
    name: 'TheLayoutDrawer',
    components: {
        TheLayoutDrawerList,
    },
    data: () => ({
        routes: [],
    }),
    computed: {
        ...mapGetters([
            'permissionRoutes',
            'navbarDense',
            'navbarShow',
            'navbarLogo',
            'toolbarDense',
            'name',
            'avatar',
        ]),
        avatarToUse() {
            if (this.avatar) {
                return this.avatar;
            }

            return '/img/avatars/default.png';
        },
    },
    mounted() {
        // this.$http.get(`${process.env.VUE_APP_NAVIGATION_API}/navigation`)
        //   .then(({ data }) => {
        //     this.routes = data;
        //   });
    },
    methods: {
        stateNavbarShow(state) {
            this.$store.dispatch('NavbarState', {
                state
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.left-notch {
    background-color: #fff;

    &::before {
        content: "";
        width: 6px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
    }
}
</style>
