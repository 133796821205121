import NProgress from 'nprogress'; // progress bar
import router, { asyncRoutes } from './index';
import store from '../store';
import authRouter from './modules/auth';
import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({ showSpinner: false }); // NProgress Configuration

/**
 * Generate white list
 */
const whiteList = (['/landing', '/land']
  .concat(Array.from(authRouter, (route) => route.path))
  .concat(Array.from(authRouter, (route) => route.alias)))
  .filter((route) => route); // remove undefined element
console.log('[router.whiteList]', whiteList);

/**
 * Check user has permission for this routes.
 * 'admin' permission passed directly.
 * @param {Array} roles
 * @param {Array} permissionRoles
 */
// eslint-disable-next-line no-unused-vars
function hasPermission(roles, permissionRoles) {
  if (roles.includes('admin')) return true;
  if (!permissionRoles) return true;
  return roles.some((role) => permissionRoles.includes(role));
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  console.log("from:", from, "to:", to)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('needs auth')
    if (router.app.$keycloak ? router.app.$keycloak.authenticated : false) {
      console.log('has auth')
      const profile = await router.app.$keycloak.loadUserProfile();
      console.log(profile);
      store.commit('SET_USER_INFO', {
        user: profile.username,
        name: `${profile.firstName} ${profile.lastName}`,
      });
      store.commit('SET_ROUTES', asyncRoutes);
      next();
    } else {
      next('/gateway')
    }
  } else if(to.matched.length >= 1) {
    if(from.matched.length < 1 && to.name != 'gateway') {
      if(to.name === 'options' || to.name === 'payment' || to.name === 'GCash' || to.name ==='Landbank' || to.name === 'success' || to.name === 'reroute' || to.name === 'fail') {
        next()
      } else {
        next('/gateway')
      }
    } else {
      next()
    }
  } else {
    next('/gateway')
  }
  NProgress.done();
});

router.afterEach(async () => {
  NProgress.done();
});
