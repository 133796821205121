import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import axios from 'axios';
import VueAxios from 'vue-axios';

import vuetify from './plugins/vuetify';
import i18n from './locale'; // Internationalization

import App from './App.vue';
import router from './router';
import store from './store';
import './router/permission';

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'


Vue.use(VueAxios, axios);
Vue.use(VueMaterial)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// Vue.use(VueKeyCloak, {
//   init: {
//     onLoad: 'check-sso',
//   },
//   config: {
//     realm: process.env.VUE_APP_KEYCLOAK_REALM,
//     url: process.env.VUE_APP_KEYCLOAK_URL,
//     clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
//   },
//   onReady: () => {
//     new Vue({
//       router,
//       store,
//       i18n,
//       vuetify,
//       render: (h) => h(App),
//     }).$mount('#app');
//   },
// });
